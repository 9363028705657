// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-specialties-js": () => import("./../src/pages/our-specialties.js" /* webpackChunkName: "component---src-pages-our-specialties-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-sitemap-js": () => import("./../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-treatment-services-js": () => import("./../src/pages/treatment-services.js" /* webpackChunkName: "component---src-pages-treatment-services-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-resources-js": () => import("./../src/templates/resources.js" /* webpackChunkName: "component---src-templates-resources-js" */),
  "component---src-templates-resources-list-js": () => import("./../src/templates/resources-list.js" /* webpackChunkName: "component---src-templates-resources-list-js" */),
  "component---src-templates-service-category-js": () => import("./../src/templates/service-category.js" /* webpackChunkName: "component---src-templates-service-category-js" */),
  "component---src-templates-service-js": () => import("./../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-specialty-category-js": () => import("./../src/templates/specialty-category.js" /* webpackChunkName: "component---src-templates-specialty-category-js" */),
  "component---src-templates-specialty-js": () => import("./../src/templates/specialty.js" /* webpackChunkName: "component---src-templates-specialty-js" */)
}

